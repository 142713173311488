<template functional>
  <div class="OnboardingWrapper">
    <div class="Onboarding is-flex is-aligned-middle is-aligned-center">
      <div class="container Onboarding__content">
        <div class="columns is-centered is-gapless">
          <div
            v-loading="props.isLoading"
            class="column is-12-mobile is-10-tablet is-8-desktop"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * @module OnboardingWrapper
 */
export default {
  name: 'OnboardingWrapper',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.OnboardingWrapper {
  display: flex;
}

.Onboarding {
  background-color: $grey-lighter;
  min-height: 100vh;
  width: 100%;
}

.Onboarding__content {
  max-width: 1200px;
  width: 100%;
  margin: 0;
}
</style>
