<template>
  <onboarding-wrapper class="Signup">
    <base-onboarding-step
      v-if="!isComplete"
      :step="step"
      :total-steps="totalSteps"
      :cover-image="$asset('/images/onboarding/step2.jpg')"
    >
      <transition mode="out-in" name="fade">
        <component
          :is="currentStepComponent"
          :key="`OnboardingStep${step}`"
          v-model="form"
          :validator="$v.form"
          @next="goNext"
          @prev="goPrev"
        />
      </transition>
    </base-onboarding-step>

    <base-onboarding-step v-else>
      <template slot="body">
        <onboarding-step4
          :user-email="userEmail"
        />
      </template>
    </base-onboarding-step>
  </onboarding-wrapper>
</template>

<script>
import Form from '@/services/forms/Form'
import { required, email } from 'vuelidate/lib/validators'

import BaseOnboardingStep from '@/components/onboarding/BaseOnboardingStep'
import OnboardingWrapper from '@/components/onboarding/OnboardingWrapper'

function createSignupForm () {
  return new Form({
    first_name: { value: '', rules: { required } },
    last_name: { value: '', rules: { required } },
    email: { value: '', rules: { required, email } },
    country_iso2: { value: '', rules: { required } },
    brand_name: { value: '', rules: { required } },
    payment_method_id: { value: '', rules: { required } },
    plan: { value: '', rules: { required } },
    plan_duration: { value: '', rules: { required } }
  })
}

/**
 * @module Signup
 */
export default {
  name: 'Signup',
  components: { BaseOnboardingStep, OnboardingWrapper },
  data () {
    return {
      form: createSignupForm(),
      step: 1,
      totalSteps: 2
    }
  },
  validations () {
    return {
      form: this.form.rules()
    }
  },
  computed: {
    isComplete () {
      return this.step > this.totalSteps
    },

    currentStepComponent () {
      const component = import(`@/components/signup/steps/SignupStep${this.step}`)
      return () => component
    }
  },
  methods: {
    goPrev () {
      this.step = this.step - 1
    },
    goNext () {
      this.step = this.step + 1
    }
  }
}
</script>
